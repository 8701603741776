#root:has(>.nav-bar) {
  &>.nav-bar {
    margin-inline: 12px;
  }
}
.ant-drawer-title {
  color: #652d52;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & .ant-card-meta-title {
    color:#652d52
  }
}


.nav-bar {
  padding: 8px;
  display: flex;
  position: fixed;
  bottom: 12px;
  left: 2.5dvw;
  right: 2.5dvw;
  box-sizing: border-box;
  border-radius: 16px;
  justify-content: space-between;
  backdrop-filter: blur(12px);
  border: 1px solid #652d52;
  height: fit-content;
  color: #fd941b;
  /* width: 95vw; */
  background-color: #652d5285;
  z-index: 2;

  @media (width >=768px) {
    display: none;
  }

  & .nav-bar-opt-center,
  .nav-bar-opt-center-ser {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: -40px;
    box-shadow: 0px 2px 2px 1px #f8f7f829;
    width: 80px;

    svg {
      margin-left: 5px;
      height: 32px;
      width: 32px;
      border: 2px;
      display: block;
      color: #fd941b;
      stroke-width: 8 !important;

    }
  }

  .nav-bar-opt-center-ser {
    svg {
      margin: 0
    }
  }

  & .nav-bar-opt {
    background-color: transparent !important;
    box-sizing: border-box;
    height: fit-content;
    border: none !important;


    & svg {
      height: 32px;
      width: 32px;
      color: white;
      display: block;
      stroke-width: 8 !important;
    }
  }

  & :is([data-active="true"]) {
    border-radius: 12px;
    border: 1px solid white !important;
    background-color: #652d523b !important;

    & svg {
      color: #fff;
      fill: #fff;
      stroke: #fff;

      stroke-width: 8;
    }
  }
}

.options-modal {
  & .add-new-option {
    display: flex;
    gap: 6px;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: center;

    & :is(:nth-child(1), :last-child) {
      flex: 2;
    }

    &>button {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      flex-basis: 120px;
      flex: 1;

      &:is(:active, :hover) {
        background-color: color-mix(in srgb, transparent 98%, #fd941b) !important;
        border-color: color-mix(in srgb, transparent 60%, #fd941b) !important;

        & .ant-btn-icon {
          & svg {
            color: color-mix(in srgb, transparent 50%, #fd941b);
            transform: scale(1.2);
          }
        }
      }

      &>:not(.ant-btn-icon) {
        white-space: wrap;
        font-size: 13px;
        font-weight: 500;
        color: color-mix(in srgb, transparent 30%, #652d52);
        margin-block: auto 0px;
        line-height: normal;
        height: fit-content;
      }

      & .ant-btn-icon {
        display: flex;
        margin-block: auto;

        & svg {
          height: 38px;
          width: 38px;
          color: #652d52;
          display: block;
          transition: .2s ease-in-out;
          transition-property: transform, color;
          color: color-mix(in srgb, transparent 40%, currentColor);

          &:is(.worker-icon) {
            height: 52px;
            width: 52px;
          }
        }
      }
    }
  }
}


/* request controller*/
.request-controller-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.section-request-controller {
  padding: 20px;

  header{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}