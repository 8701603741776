.pdf-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  color: inherit;
}

.pdf-card:hover {
  background-color: #e6e6e6;
}

.pdf-icon {
  font-size: 2rem;
}

.pdf-info {
  flex: 1;
}

.pdf-title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.pdf-subtext {
  margin: 0.2rem 0 0;
  font-size: 0.85rem;
  color: #555;
}
